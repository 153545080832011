/*******  Use relative than absolute unit values  *********/
/**********************************************************/
/* /core/public/shared/assets/css/responsive.css */
.fly-out-menu-container .fly-out-menu-button .glyph-keyboard_arrow_down {
	font-size: 1.25rem;
}

/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
	font-size: 0.6875rem;
}

/* /core/website/modules/feeds/css/screen.css.cfm */
#feeds_module.cms_list .cms_list_item .cms_date {
	font-size: 0.75rem;
}


#feeds_module.cms_list .cms_list_item .cms_metadata2.cms_date {
	font-size: 0.6875rem;
}

#feeds_module.homepage_contentbox .item .date-published {
	font-size: 0.6875rem;
}

/* core/website/modules/submissionforms/css/print.css*/
.module-forms.confirmation .step-title {
	font-size: 0.875rem;
}

/* /core/website/modules/submissionforms/css/screen.css */
#submissionforms_module.cms_form .cms_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.cms_form .cms_date_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.cms_form .agreement_hint {
	font-size: 0.6875rem;
}

.module-forms.confirmation .step-title {
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox .cms_hint,
#submissionforms_module.homepage_contentbox .cms_date_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.6875rem;
}

.module-forms .ui-form-field-payment-information,
.module-forms .ui-data-addresses h3 {
	font-size: 0.875rem;
}

/**********  Removing the underline from links  ***********/
/**********************************************************/
/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-list-item-title a {
	text-decoration: underline;
}

/* /core/website/modules/faqs/css/screen.css */
#faqs_module .cms_list_item ul li a,
#faqs_module .cms_list_subitem ul li a {
	text-decoration: underline;
}

/* /core/website/modules/news/css/screen.css */
.archive_links a,
.archive_links a.selected {
	text-decoration: underline;
}

/*  All information with color is available without color  */
/**********************************************************/
/* core/public/shared/assets/css/responsive.css */
.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	color: #999999;
	font-style: italic;
}

/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-unthemed .fc-popover {
	background-color: #fff;
	font-weight: bold;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
	color: #666;
	font-weight: bold;
}

.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	color: #333333;
	font-weight: bold;
}

.fc-unthemed .fc-list-empty { /* theme will provide own background */
	background-color: #eee;
	font-weight: bold;
}

/* /core/website/modules/events/css/screen.css */
#events_module.cms_list .cms_button:hover {
	background-color: #dddddd;
	box-shadow: 0 0px 2px #333;
}

#events_module.cms_list .cms_oddRow {
	background: #ffffff;
}

#events_module.cms_form .cms_button:hover {
	background-color: #dddddd;
	box-shadow: 0 0px 1px #ccc;
}

#events_module.cms_list .fc-list-item:hover td .fc-event-dot[style="background-color:#FFFFFF"] {
	background-color: #f5f5f5 !important;
	border-radius: 0;
}

.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	color: #333333;
	background-color: #e6e6e6 !important;
	font-weight: bold;
}

.full_calendar_view .fc-state-active {
	font-weight: bold;
	background-color: #cccccc !important;
}

#events_module.homepage_contentbox.conference-tabs .conference-heading.tabbed.current {
	background-color: #ffffff;
	color: #000000;
	font-weight: bold;
}

#events_module.homepage_contentbox.conference-tabs .tab-heading-text {
	color: inherit;
	font-size: inherit;
}

#events_module.homepage_contentbox.conference-tabs .schedule-wrapper {
	background: #ffffff;
}

/* /core/website/modules/submissionforms/css/screen.css */
#submissionforms_module.cms_form .cms_steps_wrapper .active,
#submissionforms_module.cms_form .cms_steps_wrapper .last_active {
	background-color: #d1d0ce;
	font-style: italic;
}

#submissionforms_module.cms_form .container-buttons-wrapper span.container-button:hover,
#submissionforms_module.homepage_contentbox .container-buttons-wrapper span.container-button:hover {
	background-color: hsla(90, 20%, 65%, 0.15);
	box-shadow: 0px 1px 4px #9ba694;
}

#submissionforms_module.cms_form .container-buttons-wrapper span.add:hover,
#submissionforms_module.homepage_contentbox .container-buttons-wrapper span.add:hover {
	color: #348300;
	box-shadow: 0px 1px 4px #9ba694;
}

#submissionforms_module.cms_form .container-buttons-wrapper span.remove:hover,
#submissionforms_module.homepage_contentbox .container-buttons-wrapper span.remove:hover {
	color: #d3121a;
	box-shadow: 0px 1px 4px #9ba694;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button:hover {
	background-color: #dddddd;
	cursor: pointer;
}

#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button:hover {
	background-color: #dddddd;
	cursor: pointer;
}


/***  Foreground and background colors have contrast  *****/
/**********************************************************/
/* /core/public/shared/assets/css/responsive.css */
.fly-out-menu-list-container ul.fly-out-menu-list li a:active,
.fly-out-menu-list-container ul.fly-out-menu-list li.selected a {
	background: #11406f !important;
	color: #ffffff !important;
}

/* /core/public/shared/assets/css/screen.css */
.search-facets .search-facet .price-range-button a {
	background-color: #4f4f4f;
	color: #ffffff;
}

/* /core/website/modules/events/css/screen.css */
#events_module.cms_list div.events_module_calendar .fc-view-container .fc-event {
	background-color: #9c9c9c;
	color: #000000;
	font-size: 0.75rem;
}


/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-basic-view .fc-day-top .fc-week-number {
	background-color: #f2f2f2;
	color: #2e2e2e;
}
